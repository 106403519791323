@import url('https://fonts.cdnfonts.com/css/bodoni');

body {
    overflow-x: hidden;
}

.header-image {
    height: 90vh; 
    width: 100vw;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}


.header-text {
    color: white;
    font-family: 'Bodoni', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
}

.experience-section {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    line-height: 2.0;
    font-size: 20px;
    margin-right: 50px;
    margin-top: 50vh;
}

@media (max-width: 600px) {
    .experience-section {
        flex-direction: column;
        align-items: center;
        padding: 20px;
        margin-right: 20px;
    }
    .experience-title {
        padding: 10px;
        justify-content: center;
        align-items: center;
    }
    .testimonial-header {
        text-align: center !important;
    }
}

.testimonial-header {
    text-align: center !important;
  }
  
.experience-title {
    flex: 1;
    padding: 20px;
    margin-left: 50px;
}

.experience-description {
    flex: 2;
    padding: 20px;
}

.testimonial-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    justify-items: center;
}

.testimonial-grid .testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonial-grid img {
    width: 100%;
    max-width: 200px;
}

.quote-section {
    background-size: cover;
    background-position: center;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 400px; /* Adjust this value as needed */
}

.quote-text {
    font-size: 2em;
    text-align: center;
    max-width: 80%;
}

