@import url('https://fonts.cdnfonts.com/css/bodoni');

body {
    overflow-x: hidden;
}

.contact-header-image {
    height: 90vh; 
    width: 100vw;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}


.contact-header-text {
    color: white;
    font-family: 'Bodoni', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 10px;
}

.contact-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    font-family: 'Audrey', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40vh;
}

.contact-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
}

.contact-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-flex-container {
    display: flex;
    flex-direction: row; /* Default to row on larger screens */
}

@media (max-width: 600px) {
    .contact-flex-container {
        flex-direction: column; /* Stack the columns vertically on smaller screens */
        align-items: center;
        text-align: center;
    }
}