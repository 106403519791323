@import url('https://fonts.cdnfonts.com/css/bodoni');

body {
    overflow-x: hidden;
}

.services-header-image {
    height: 90vh; 
    width: 100vw;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.services-header-text {
    color: white;
    font-family: 'Bodoni', sans-serif;
}

.services-title {
    flex: 1;
    padding: 20px;
    margin-left: 50px;
}

@media (max-width: 600px) {
    .services-section {
        flex-direction: column;
    }
}

.services-section {
    display: block;
    justify-content: space-between;
    padding: 50px 0;
    line-height: 2.0;
    font-size: 20px;
    margin-top: 50vh;
    margin-left: auto;
    margin-right: auto;
}