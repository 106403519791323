@import url('https://fonts.cdnfonts.com/css/bodoni');

body {
    overflow-x: hidden;
}

.gallery-header-image {
    height: 90vh; 
    width: 100vw;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}


.gallery-header-text {
    color: white;
    font-family: 'Bodoni', sans-serif;
}

.gallery-section {
    margin-top: 30vh;
}