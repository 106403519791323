.navbar {
    position: fixed;
    top: 0;
    width: 98%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center; /* Add this line */
    z-index: 1000; /* Ensure the navbar is above other elements */
  }
  
  .navbar-logo {
    max-width: 200px;
    max-height: 200px;
  }
  
  .navbar-links a {
    margin-left: 20px;
    text-decoration: none;
    color: white; /* Adjust as needed */
  }

  .navbar.hidden {
    display: none;
}

@media (max-width: 992px) {
  .navbar-collapse {
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
}